
  import { defineComponent, ref, onBeforeMount, computed, reactive, toRefs, watch } from 'vue';
  import { useToast } from "vue-toastification";
  import { useRoute } from 'vue-router';
  import { useScreen } from 'vue-screen';

  import ChildrenMenu from '@/views/children/components/ChildrenMenu.vue';
  import ChildInfo from '@/views/children/components/ChildInfo.vue';
  import AppButton from '@/components/stateless/AppButton.vue';
  import AppIcon from '@/components/stateless/AppIcon.vue';
  import AppTabs from '@/components/stateless/AppTabs.vue';
  import RegistrationBanner from '@/components/RegistrationBanner.vue';
  import UpcomingActivities from '@/components/activities-rsvp/UpcomingActivities.vue';
  import RegisteredActivities from '@/components/activities-rsvp/RegisteredActivities.vue';
  import Banner from '@/components/Banner.vue';
  import PaginationCounter from '@/components/PaginationCounter.vue';

  import { IChild, IActivity, TIndexedObject, ITableHeaders } from '@/types';
  import { routesNames, router } from '@/router';
  import { vuex } from '@/store';
  import { childrenService } from '@/services';
  import { useActivityRegistration } from '@/components/activities-rsvp/useActivityRegistartion';
  import {
    registeredChildActivities,
    childActivitiesWithoutRegistrations,
    ACTIVITIES_PAGINATION_LIMIT
  } from '@/views/children/api-params';

  export default defineComponent({
    name: 'ChildrenView',

    components: {
      ChildrenMenu,
      AppButton,
      ChildInfo,
      AppIcon,
      AppTabs,
      RegistrationBanner,
      UpcomingActivities,
      RegisteredActivities,
      Banner,
      PaginationCounter
    },

    setup() {
      const toast = useToast();
      const route = useRoute();
      const screen = useScreen();
      const showBanner = ref<boolean>(true);
      const activeChildId = ref<string>('');
      const desktopPaginationPages = reactive({
        myActivitiesPage: 0,
        exploreActivitiesPage: 0
      }) as TIndexedObject;
      const mobilePaginationPages = reactive({
        myActivitiesPage: 1,
        exploreActivitiesPage: 1
      }) as TIndexedObject;
      const state = reactive({
        children: [] as IChild[],
        activeTab: {} as TIndexedObject
      });

      const {
        externalLink,
        openCancellationModal,
        openRegisterModal,
        upcomingActivitiesList,
        registeredActivitiesList,
        registeredCount,
        withoutRegistrationCount,

        handleCancellation,
        confirmCancellation,
        discardCancellation,
        handleBrowsing,

        confirmRegistration,
        discardRegistration,
        registerChildForActivity,

        fetchRegisteredActivities,
        fetchActivitiesWithoutRegistrations,
      } = useActivityRegistration({
        updateActivitiesCb: refetchActivities
      });

      const { children, activeTab } = toRefs(state);

      const tableHeaders = computed<ITableHeaders[]>(() => [
        { property: 'name', label: 'Activity Name', minWidth: 250 },
        { property: 'description', label: 'Description', minWidth: 500 },
        { property: 'startDate', label: 'Start Date', sortable: true, minWidth: 140 },
        { property: 'endDate', label: 'End Date', sortable: true, minWidth: 130 },
        { property: 'status', minWidth: 300, show: screen.width > 769 },
      ]);

      const tabs: TIndexedObject[] = [
        { value: 'available', label: 'Available' },
        { value: 'registered', label: 'Registered' },
      ];

      const activeChild = computed<IChild | undefined>(() => {
        return activeChildId.value
          ? children.value.find((el: IChild) => el.id === activeChildId.value)
          : children.value[0];
      });

      const myActivitiesOffset = computed<number>(() => {
        return desktopPaginationPages.myActivitiesPage * ACTIVITIES_PAGINATION_LIMIT;
      });

      const exploreActivitiesOffset = computed<number>(() => {
        return desktopPaginationPages.exploreActivitiesPage * ACTIVITIES_PAGINATION_LIMIT;
      });

      function handlePageChanged(activitiesType: string, page: number) {
        desktopPaginationPages[activitiesType] = page - 1;

        refetchActivities();
      }

      watch(
        () => route,
        (val) => {
          if (val.name === routesNames.childrenView && val.query.id) {
            refetchActivities();
          }
        },
        { deep: true }
      );

      function hideBanner() {
        showBanner.value = false;
      }

      function resetDesktopPage(value: 'cancelation' | 'registration') {
        if (value === 'cancelation') {
          if (registeredActivitiesList.value.length === 1) {
            desktopPaginationPages.myActivitiesPage = 0;
          }
        } else {
          if (upcomingActivitiesList.value.length === 1) {
            desktopPaginationPages.exploreActivitiesPage = 0;
          }
        }
      }

      function loadMoreActivities(value: string) {
        vuex.setLoading(true);

        if (value === 'registered') {
          mobilePaginationPages.myActivitiesPage += 1;
          fetchRegisteredActivities(
            registeredChildActivities(
              activeChildId.value,
              0,
              mobilePaginationPages.myActivitiesPage * ACTIVITIES_PAGINATION_LIMIT
            )
          )
            .finally(() => vuex.setLoading(false));
        } else {
          mobilePaginationPages.exploreActivitiesPage += 1;
          fetchActivitiesWithoutRegistrations(
            childActivitiesWithoutRegistrations(
              activeChildId.value,
              0,
              mobilePaginationPages.exploreActivitiesPage * ACTIVITIES_PAGINATION_LIMIT
            )
          )
            .finally(() => vuex.setLoading(false));
        }
      }

      function resetMobilePagination() {
        mobilePaginationPages.myActivitiesPage = 1;
        mobilePaginationPages.exploreActivitiesPage = 1;
      }

      function handleRegisterChildForActivity(activity: IActivity) {
        registerChildForActivity(activity, activeChildId.value);
      }

      function initActiveChild() {
        const { id } = route.query;

        if (id) {
          const activeChild = children.value.find((el: IChild) => el.id.toString() === id);

          if (activeChild) {
            activeChildId.value = activeChild.id;
            return;
          }
        }

        activeChildId.value = children.value.length ? children.value[0]?.id : '';
      }

      async function fetchChildren() {

        return childrenService.fetchChildren()
          .then((res: IChild[]) => {
            children.value = res;
            initActiveChild();
            return res;
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          });
      }

      function handleEditClick() {
        router.push({
          name: routesNames.updateChild,
          query: { id: activeChildId.value },
          params: { childrenAmount: children.value.length }
        });
      }

      async function fetchActivities() {
        return Promise.all([
          fetchRegisteredActivities(registeredChildActivities(activeChildId.value, myActivitiesOffset.value)),
          fetchActivitiesWithoutRegistrations(
            childActivitiesWithoutRegistrations(activeChildId.value, exploreActivitiesOffset.value)),
        ]);
      }

      async function refetchActivities() {
        vuex.setLoading(true);

        return fetchActivities()
          .finally(() => {
            resetMobilePagination();
            vuex.setLoading(false);
          });
      }

      async function fetchInitData() {
        vuex.setLoading(true);

        return fetchChildren()
          .then(() => {
            if (children.value.length) {
              return fetchActivities();
            }
          })
          .finally(() => vuex.setLoading(false));
      }

      onBeforeMount(async () => {
        await fetchInitData();
      });

      return {
        children,
        router,
        upcomingActivitiesList,
        registeredActivitiesList,
        showBanner,
        tabs,
        activeTab,
        tableHeaders,
        registeredCount,
        withoutRegistrationCount,
        ACTIVITIES_PAGINATION_LIMIT,
        myActivitiesOffset,
        exploreActivitiesOffset,

        activeChildId,
        vuex,
        activeChild,

        externalLink,
        openCancellationModal,
        openRegisterModal,

        handleEditClick,
        refetchActivities,
        hideBanner,
        handlePageChanged,

        handleCancellation,
        confirmCancellation: (id: number) => { 
          resetDesktopPage('cancelation');
          confirmCancellation(id); 
        },
        discardCancellation,
        handleBrowsing,
        loadMoreActivities,

        confirmRegistration: (id: number) => { 
          resetDesktopPage('registration'); 
          confirmRegistration(id); 
        },
        discardRegistration,
        handleRegisterChildForActivity
      };
    }
  });
