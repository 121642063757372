
  import { defineComponent, PropType } from 'vue';

  import AppIcon from '@/components/stateless/AppIcon.vue';

  import { IChild } from '@/types';

  export default defineComponent({
    name: 'Children',

    components: { AppIcon },

    props: {
      data: {
        type: Object as PropType<IChild>,
        required: true
      }
    }
  });
