import { IRequestParams } from '@/types';

export const ACTIVITIES_PAGINATION_LIMIT = 10;

export const registeredChildActivities = 
(id: number | string, offset?: number, limit = ACTIVITIES_PAGINATION_LIMIT): IRequestParams => ({
  params: {
    filter: {
      limit,
      offset,
      where: {
        endDate: { $gt: new Date().toISOString() },
        type: 'child'
      },
      order: [
        [
          "placementFlag",
          "DESC"
        ],
        [
          "startDate", "asc"
        ]
      ],
      include: [
        {
          association: "registrations",
          required: true,
          where: {
            childId: id,
            status: {
              $in: [
                "initialCancelled",
                "activityCancelled",
                "active"
              ]
            }
          }
        },
        { model: 'category' }
      ]
    },
    includeCount: true
  }
});

export const childActivitiesWithoutRegistrations = 
(id: number | string,  offset?: number, limit = ACTIVITIES_PAGINATION_LIMIT): IRequestParams => ({
  params: {
    filter: {
      limit,
      offset,
      order: [
        [
          "placementFlag",
          "DESC"
        ],
        [
          "startDate", "asc"
        ]
      ],
      where: {
        endDate: { $gt: new Date().toISOString() },
        type: 'child',
        $and: [
          {
            $or: [
              {
                "$registrations.status$": "initial"
              },
              {
                "$registrations.id$": null
              }
            ]
          },
          {
            status: "active"
          }
        ]
      },
      include: [
        {
          association: "registrations",
          where: {
            childId: id,
            status: {
              $ne: "cancelled"
            }
          }
        },
        { model: 'category' }
      ]
    },
    includeCount: true
  }
});

export const SCHOOLS_PARAMS: IRequestParams = {
    params: {
      filter: {
        order: [
          ["createdAt", "DESC"]
        ]
      },
      includeCount: true
    }
  };