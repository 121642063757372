<template>
  <div class="flex flex-col md:flex-row md:items-center flex-wrap w-full xs:w-auto">
    <div class="flex flex-col md:flex-row md:items-center md:mb-20 w-full xs:w-auto">
      <!-- S C H O O L  -->
      <div class="mb-16 md:mb-0 flex items-center xs:mr-40 max-w-300">
        <div>
          <AppIcon
            size="24"
            name="school"
            class="text-grey-fp-80"
          />
        </div>
        <p class="ml-10 text-grey-fp-70 truncate">
          {{ !data.school ? data.otherSchoolName : data.school.name }}
        </p>
      </div>

      <!-- G R A D E  -->
      <div class="mb-16 md:mb-0 flex items-center xs:mr-40">
        <div>
          <AppIcon
            size="24"
            name="school-engagement"
            class="text-grey-fp-80"
          />
        </div>
        <p class="ml-10 text-grey-fp-70 truncate">{{ data.grade }}</p>
      </div>
    </div>

    <div class="flex flex-col md:flex-row md:items-center md:mb-20 w-full xs:w-auto">
      <!-- N I C K N A M E  -->
      <div
        v-if="data.nickname"
        class="mb-16 md:mb-0 flex items-center xs:mr-40 max-w-250"
      >
        <div>
          <AppIcon
            size="24"
            name="nickname"
            class="text-grey-fp-80"
          />
        </div>
        <p class="ml-10 text-grey-fp-70 truncate">{{ data.nickname }}</p>
      </div>

      <!-- S T U D E N T  I D -->
      <div
        v-if="data.studentId"
        class="mb-16 md:mb-0 flex items-center xs:mr-40"
      >
        <div>
          <AppIcon
            size="24"
            name="user"
            class="text-grey-fp-80"
          />
        </div>
        <p class="ml-10 text-grey-fp-70 truncate">{{ data.studentId }}</p>
      </div>

      <!-- B I R T H D A Y -->
      <div class="mb-16 md:mb-0 flex items-center xs:mr-40">
        <div>
          <AppIcon
            size="24"
            name="birthday"
            class="text-grey-fp-80"
          />
        </div>
        <p class="ml-10 text-grey-fp-70 truncate">{{ $filters.date(data.birthMonthDate, 'DD MMM YYYY') }}</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';

  import AppIcon from '@/components/stateless/AppIcon.vue';

  import { IChild } from '@/types';

  export default defineComponent({
    name: 'Children',

    components: { AppIcon },

    props: {
      data: {
        type: Object as PropType<IChild>,
        required: true
      }
    }
  });
</script>