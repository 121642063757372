
  import { defineComponent, PropType, ref, onMounted } from 'vue';
  import { useRouter } from 'vue-router';

  import AppUserAvatar from '@/components/stateless/AppUserAvatar.vue';
  import AppIcon from '@/components/stateless/AppIcon.vue';

  import { IChild } from '@/types';
  import { routesNames } from '@/router';

  export default defineComponent({
    name: 'Children',

    components: { AppUserAvatar, AppIcon },

    props: {
      activeChildId: {
        type: [Number, String],
        required: true
      },

      children: {
        type: Array as PropType<IChild[]>,
        required: true
      }
    },

    emits: ['update:activeChildId', 'change'],

    setup(props, { emit }) {
      const router = useRouter();
      const childrenMenu: any = ref(null);
      const isMounted = ref<boolean>(false);

      function scrollToActive() {
        const activeElement = document.querySelector('.user-avatar-wrap.active');

        activeElement?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
      }

      function onChildClick(id: string) {
        emit('update:activeChildId', id);
        emit('change', id);
        router.replace({ query: { id } });
      }

      onMounted(() => {
        isMounted.value = true;
        setTimeout(() => { scrollToActive(); }, 1);
      });

      return {
        childrenMenu,
        router,
        routesNames,

        onChildClick,
      };
    }
  });
