<template>
  <div class="bg-white p-14 xs:p-20 rounded-5">
    <div
      ref="childrenMenu"
      class="children-menu flex items-center rounded-5 w-full overflow-x-auto custom-scrollbar p-10 gap-10"
    >
      <!-- M E N U  I T E M S -->
      <div
        v-for="child of children"
        :key="child.id"
        class="user-avatar-wrap cursor-pointer"
        :class="{'active': child.id === activeChildId}"
        @click="onChildClick(child.id)"
      >
        <AppUserAvatar
          :username="child.firstName + ' ' + child.lastName"
          class="user-avatar text-1xl xs:text-3xl xs:h-100 xs:w-100 h-56 w-56 mb-8"
        />
        <div class="userName text-center flex xs:flex-row flex-col xs:text-md text-grey-fp-70 truncate">
          <span class="xs:mr-5 truncate"> {{ child.firstName }} </span>
          <span class="truncate"> {{ child.lastName }} </span>
        </div>
      </div>

      <!-- A D D  C H I L D  B U T T O N -->
      <div
        class="add-child flex flex-col items-center justify-center cursor-pointer mb-auto min-w-100"
        @click="router.push({ name: routesNames.addChild })"
      >
        <div class="icon-wrap h-56 w-56 xs:h-100 xs:w-100">
          <AppIcon
            name="plus"
            size="32"
            class="hidden xs:inline"
          />
          <AppIcon
            name="plus"
            size="20"
            class="inline xs:hidden"
          />
        </div>
        <p class="add-child-text xs:text-md text-grey-fp-70 text-primary">Add Child</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType, ref, onMounted } from 'vue';
  import { useRouter } from 'vue-router';

  import AppUserAvatar from '@/components/stateless/AppUserAvatar.vue';
  import AppIcon from '@/components/stateless/AppIcon.vue';

  import { IChild } from '@/types';
  import { routesNames } from '@/router';

  export default defineComponent({
    name: 'Children',

    components: { AppUserAvatar, AppIcon },

    props: {
      activeChildId: {
        type: [Number, String],
        required: true
      },

      children: {
        type: Array as PropType<IChild[]>,
        required: true
      }
    },

    emits: ['update:activeChildId', 'change'],

    setup(props, { emit }) {
      const router = useRouter();
      const childrenMenu: any = ref(null);
      const isMounted = ref<boolean>(false);

      function scrollToActive() {
        const activeElement = document.querySelector('.user-avatar-wrap.active');

        activeElement?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
      }

      function onChildClick(id: string) {
        emit('update:activeChildId', id);
        emit('change', id);
        router.replace({ query: { id } });
      }

      onMounted(() => {
        isMounted.value = true;
        setTimeout(() => { scrollToActive(); }, 1);
      });

      return {
        childrenMenu,
        router,
        routesNames,

        onChildClick,
      };
    }
  });
</script>

<style lang="scss" scoped>
  .children-menu {
    .user-avatar {
      @apply text-primary bg-blue-fp-20;
    }

    .active {
      .userName {
        @apply text-primary;
      }
      .user-avatar {
        @apply bg-primary text-white;
      }
    }

    .userName {
      @apply max-w-90 xs:max-w-130;
    }

    .add-child,
    .user-avatar-wrap {
      &:hover {
        .userName {
          @apply text-primary;
        }
      }
    }

    .add-child {
      .icon-wrap {
        @apply bg-blue-fp-20 text-primary flex items-center justify-center rounded-half mb-8;
      }
    }

    .user-avatar-wrap {
      @apply flex flex-col items-center justify-center xs:mr-40 w-100 xs:w-130 w-100 xs:w-130;
    }
  }
</style>