<template>
  <div>
    <Banner class="mb-25" />
    <!-- C H I L D R E N  M E N U -->
    <ChildrenMenu
      v-model:active-child-id="activeChildId"
      :children="children"
      class="mb-16 xs:mb-25"
    />
    <!-- C H I L D  S U M M A R Y  I N F O -->
    <div
      v-if="activeChildId"
      class="summary xs:bg-white xs:p-30 rounded-5 w-full mb-25"
    >
      <div class="rounded-5 bg-white xs:bg-transparent p-14 xs:p-0 mb-16 xs:mb-0">
        <div class="flex items-center justify-between mb-15">
          <p class="text-primary">Summary</p>

          <!-- E D I T  C H I L D  B U T T O N  D E S K T O P -->
          <AppButton
            type="primary"
            size="mini"
            class="hidden xs:flex px-20"
            @click="handleEditClick"
          >
            Edit information
          </AppButton>
        </div>

        <div class="flex items-center md:mb-30 w-full">
          <ChildInfo :data="activeChild" />
        </div>

        <!-- E D I T  C H I L D  B U T T O N  M O B I L E -->
        <AppButton
          type="primary"
          size="mini"
          class="xs:hidden px-20"
          @click="handleEditClick"
        >
          Edit information
        </AppButton>
      </div>

      <div
        v-if="!activeChild.otherSchoolName && activeChild.school"
        class="rounded-5 bg-white xs:bg-transparent p-14 xs:p-0 mb-16 xs:mb-0"
      >
        <!-- Q U I C K  L I N K S -->
        <div class="mb-15 border-b border-grey-fp-20">
          <p class="text-dark-cl-20 text-md xs:text-1xl mb-15">Quick Links</p>
          <ul class="pl-10 mb-15">
            <li
              v-for="(link, index) of activeChild.school.socialLinks"
              :key="index"
              class="text-primary flex items-center mb-10 xs:mb-12"
            >
              <AppIcon
                name="point"
                size="7"
                class="mr-10"
              />
              <a
                :href="link.url"
                target="_blank"
                class="text-sm xs:text-base"
              >
                <span v-if="link.text">{{ link.text }}</span>
              </a>
            </li>
          </ul>
        </div>

        <!-- S O C I A L  L I N K S -->
        <div class="xs:mb-25 flex items-center pl-10">
          <div
            v-for="(link, index) of activeChild.school.quickLinks"
            :key="index"
            class="mr-12 last:mr-0"
          >
            <a
              :href="link.url"
              target="_blank"
            >
              <AppIcon
                :name="link.text.toLowerCase()"
                size="24"
                alt="Social"
                class="text-primary"
              />
            </a>
          </div>
        </div>
      </div>
    </div>

    <div v-if="activeChildId">
      <!-- A C T I V I T I E S   S E C T I O N -->
      <RegistrationBanner
        v-if="showBanner"
        class="mb-25"
        @close="hideBanner"
      />

      <!-- A C T I V I T I E S   D E S K T O P -->
      <div class="hidden md:block">
        <UpcomingActivities
          :activities-list="upcomingActivitiesList"
          :headers="tableHeaders"
          :title="`Register ${ activeChild.firstName } for Activities`"
          class="mb-25"
          :external-link="externalLink"
          :open-modal="openRegisterModal"
          @browse="handleBrowsing"
          @confirm="confirmRegistration"
          @discard="discardRegistration"
          @register="handleRegisterChildForActivity"
        >
          <!-- P A G I N A T I O N -->
          <template
            v-if="upcomingActivitiesList.length"
            #pagination
          >
            <div class="flex justify-between items-center py-20 border-t border-grey-fp-10">
              <PaginationCounter
                :offset="exploreActivitiesOffset"
                :limit="ACTIVITIES_PAGINATION_LIMIT"
                :total="withoutRegistrationCount"
                class="text-posey-blue"
              />

              <v-pagination
                :page-size="ACTIVITIES_PAGINATION_LIMIT"
                layout="prev, pager, next"
                hide-on-single-page
                :total="withoutRegistrationCount"
                @current-change="(val) => handlePageChanged('exploreActivitiesPage', val)"
              />
            </div>
          </template>
        </UpcomingActivities>

        <RegisteredActivities
          :activities-list="registeredActivitiesList"
          :headers="tableHeaders"
          :title="`${ activeChild.firstName } is Registered for the Following Activities`"
          :external-link="externalLink"
          :open-modal="openCancellationModal"
          @browse="handleBrowsing"
          @cancel="handleCancellation"
          @discard="discardCancellation"
          @confirm="confirmCancellation"
        >
          <!-- P A G I N A T I O N -->
          <template
            v-if="registeredActivitiesList.length"
            #pagination
          >
            <div class="flex justify-between items-center py-20 border-t border-grey-fp-10">
              <PaginationCounter
                :offset="myActivitiesOffset"
                :limit="ACTIVITIES_PAGINATION_LIMIT"
                :total="registeredCount"
                class="text-posey-blue"
              />

              <v-pagination
                :page-size="ACTIVITIES_PAGINATION_LIMIT"
                layout="prev, pager, next"
                hide-on-single-page
                :total="registeredCount"
                @current-change="(val) => handlePageChanged('myActivitiesPage', val)"
              />
            </div>
          </template>
        </RegisteredActivities>
      </div>

      <!-- A C T I V I T I E S   M O B I L E -->
      <div class="block md:hidden">
        <AppTabs
          v-model="activeTab"
          custom-tab-classes="w-full text-center"
          :items="tabs"
          class="mb-20"
        />

        <RegisteredActivities
          v-if="activeTab.value === 'registered'"
          :activities-list="registeredActivitiesList"
          :headers="tableHeaders"
          :title="`${ activeChild.firstName } is Registered for the Following Activities`"
          :external-link="externalLink"
          :open-modal="openCancellationModal"
          @browse="handleBrowsing"
          @cancel="handleCancellation"
          @discard="discardCancellation"
          @confirm="confirmCancellation"
        >
          <template
            v-if="registeredCount > registeredActivitiesList.length"
            #pagination
          >
            <div class="flex mt-10">
              <AppButton
                type="primary"
                class="mr-10 px-20 border-primary w-full"
                plain
                @click="loadMoreActivities('registered')"
              >
                Load
                {{ registeredCount - registeredActivitiesList.length >= ACTIVITIES_PAGINATION_LIMIT 
                  ? ACTIVITIES_PAGINATION_LIMIT
                  : registeredCount - registeredActivitiesList.length }}
                more
              </AppButton>
            </div>
          </template>
        </RegisteredActivities>

        <UpcomingActivities
          v-else
          :activities-list="upcomingActivitiesList"
          :headers="tableHeaders"
          :title="`Register ${ activeChild.firstName } for Student Activities`"
          :external-link="externalLink"
          :open-modal="openRegisterModal"
          @browse="handleBrowsing"
          @confirm="confirmRegistration"
          @discard="discardRegistration"
          @register="handleRegisterChildForActivity"
        >
          <template
            v-if="withoutRegistrationCount > upcomingActivitiesList.length"
            #pagination
          >
            <div class="flex mt-10">
              <AppButton
                type="primary"
                class="mr-10 px-20 border-primary w-full"
                plain
                @click="loadMoreActivities('upcoming')"
              >
                Load
                {{ withoutRegistrationCount - upcomingActivitiesList.length >= ACTIVITIES_PAGINATION_LIMIT 
                  ? ACTIVITIES_PAGINATION_LIMIT
                  : withoutRegistrationCount - upcomingActivitiesList.length }}
                more
              </AppButton>
            </div>
          </template>
        </UpcomingActivities>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref, onBeforeMount, computed, reactive, toRefs, watch } from 'vue';
  import { useToast } from "vue-toastification";
  import { useRoute } from 'vue-router';
  import { useScreen } from 'vue-screen';

  import ChildrenMenu from '@/views/children/components/ChildrenMenu.vue';
  import ChildInfo from '@/views/children/components/ChildInfo.vue';
  import AppButton from '@/components/stateless/AppButton.vue';
  import AppIcon from '@/components/stateless/AppIcon.vue';
  import AppTabs from '@/components/stateless/AppTabs.vue';
  import RegistrationBanner from '@/components/RegistrationBanner.vue';
  import UpcomingActivities from '@/components/activities-rsvp/UpcomingActivities.vue';
  import RegisteredActivities from '@/components/activities-rsvp/RegisteredActivities.vue';
  import Banner from '@/components/Banner.vue';
  import PaginationCounter from '@/components/PaginationCounter.vue';

  import { IChild, IActivity, TIndexedObject, ITableHeaders } from '@/types';
  import { routesNames, router } from '@/router';
  import { vuex } from '@/store';
  import { childrenService } from '@/services';
  import { useActivityRegistration } from '@/components/activities-rsvp/useActivityRegistartion';
  import {
    registeredChildActivities,
    childActivitiesWithoutRegistrations,
    ACTIVITIES_PAGINATION_LIMIT
  } from '@/views/children/api-params';

  export default defineComponent({
    name: 'ChildrenView',

    components: {
      ChildrenMenu,
      AppButton,
      ChildInfo,
      AppIcon,
      AppTabs,
      RegistrationBanner,
      UpcomingActivities,
      RegisteredActivities,
      Banner,
      PaginationCounter
    },

    setup() {
      const toast = useToast();
      const route = useRoute();
      const screen = useScreen();
      const showBanner = ref<boolean>(true);
      const activeChildId = ref<string>('');
      const desktopPaginationPages = reactive({
        myActivitiesPage: 0,
        exploreActivitiesPage: 0
      }) as TIndexedObject;
      const mobilePaginationPages = reactive({
        myActivitiesPage: 1,
        exploreActivitiesPage: 1
      }) as TIndexedObject;
      const state = reactive({
        children: [] as IChild[],
        activeTab: {} as TIndexedObject
      });

      const {
        externalLink,
        openCancellationModal,
        openRegisterModal,
        upcomingActivitiesList,
        registeredActivitiesList,
        registeredCount,
        withoutRegistrationCount,

        handleCancellation,
        confirmCancellation,
        discardCancellation,
        handleBrowsing,

        confirmRegistration,
        discardRegistration,
        registerChildForActivity,

        fetchRegisteredActivities,
        fetchActivitiesWithoutRegistrations,
      } = useActivityRegistration({
        updateActivitiesCb: refetchActivities
      });

      const { children, activeTab } = toRefs(state);

      const tableHeaders = computed<ITableHeaders[]>(() => [
        { property: 'name', label: 'Activity Name', minWidth: 250 },
        { property: 'description', label: 'Description', minWidth: 500 },
        { property: 'startDate', label: 'Start Date', sortable: true, minWidth: 140 },
        { property: 'endDate', label: 'End Date', sortable: true, minWidth: 130 },
        { property: 'status', minWidth: 300, show: screen.width > 769 },
      ]);

      const tabs: TIndexedObject[] = [
        { value: 'available', label: 'Available' },
        { value: 'registered', label: 'Registered' },
      ];

      const activeChild = computed<IChild | undefined>(() => {
        return activeChildId.value
          ? children.value.find((el: IChild) => el.id === activeChildId.value)
          : children.value[0];
      });

      const myActivitiesOffset = computed<number>(() => {
        return desktopPaginationPages.myActivitiesPage * ACTIVITIES_PAGINATION_LIMIT;
      });

      const exploreActivitiesOffset = computed<number>(() => {
        return desktopPaginationPages.exploreActivitiesPage * ACTIVITIES_PAGINATION_LIMIT;
      });

      function handlePageChanged(activitiesType: string, page: number) {
        desktopPaginationPages[activitiesType] = page - 1;

        refetchActivities();
      }

      watch(
        () => route,
        (val) => {
          if (val.name === routesNames.childrenView && val.query.id) {
            refetchActivities();
          }
        },
        { deep: true }
      );

      function hideBanner() {
        showBanner.value = false;
      }

      function resetDesktopPage(value: 'cancelation' | 'registration') {
        if (value === 'cancelation') {
          if (registeredActivitiesList.value.length === 1) {
            desktopPaginationPages.myActivitiesPage = 0;
          }
        } else {
          if (upcomingActivitiesList.value.length === 1) {
            desktopPaginationPages.exploreActivitiesPage = 0;
          }
        }
      }

      function loadMoreActivities(value: string) {
        vuex.setLoading(true);

        if (value === 'registered') {
          mobilePaginationPages.myActivitiesPage += 1;
          fetchRegisteredActivities(
            registeredChildActivities(
              activeChildId.value,
              0,
              mobilePaginationPages.myActivitiesPage * ACTIVITIES_PAGINATION_LIMIT
            )
          )
            .finally(() => vuex.setLoading(false));
        } else {
          mobilePaginationPages.exploreActivitiesPage += 1;
          fetchActivitiesWithoutRegistrations(
            childActivitiesWithoutRegistrations(
              activeChildId.value,
              0,
              mobilePaginationPages.exploreActivitiesPage * ACTIVITIES_PAGINATION_LIMIT
            )
          )
            .finally(() => vuex.setLoading(false));
        }
      }

      function resetMobilePagination() {
        mobilePaginationPages.myActivitiesPage = 1;
        mobilePaginationPages.exploreActivitiesPage = 1;
      }

      function handleRegisterChildForActivity(activity: IActivity) {
        registerChildForActivity(activity, activeChildId.value);
      }

      function initActiveChild() {
        const { id } = route.query;

        if (id) {
          const activeChild = children.value.find((el: IChild) => el.id.toString() === id);

          if (activeChild) {
            activeChildId.value = activeChild.id;
            return;
          }
        }

        activeChildId.value = children.value.length ? children.value[0]?.id : '';
      }

      async function fetchChildren() {

        return childrenService.fetchChildren()
          .then((res: IChild[]) => {
            children.value = res;
            initActiveChild();
            return res;
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          });
      }

      function handleEditClick() {
        router.push({
          name: routesNames.updateChild,
          query: { id: activeChildId.value },
          params: { childrenAmount: children.value.length }
        });
      }

      async function fetchActivities() {
        return Promise.all([
          fetchRegisteredActivities(registeredChildActivities(activeChildId.value, myActivitiesOffset.value)),
          fetchActivitiesWithoutRegistrations(
            childActivitiesWithoutRegistrations(activeChildId.value, exploreActivitiesOffset.value)),
        ]);
      }

      async function refetchActivities() {
        vuex.setLoading(true);

        return fetchActivities()
          .finally(() => {
            resetMobilePagination();
            vuex.setLoading(false);
          });
      }

      async function fetchInitData() {
        vuex.setLoading(true);

        return fetchChildren()
          .then(() => {
            if (children.value.length) {
              return fetchActivities();
            }
          })
          .finally(() => vuex.setLoading(false));
      }

      onBeforeMount(async () => {
        await fetchInitData();
      });

      return {
        children,
        router,
        upcomingActivitiesList,
        registeredActivitiesList,
        showBanner,
        tabs,
        activeTab,
        tableHeaders,
        registeredCount,
        withoutRegistrationCount,
        ACTIVITIES_PAGINATION_LIMIT,
        myActivitiesOffset,
        exploreActivitiesOffset,

        activeChildId,
        vuex,
        activeChild,

        externalLink,
        openCancellationModal,
        openRegisterModal,

        handleEditClick,
        refetchActivities,
        hideBanner,
        handlePageChanged,

        handleCancellation,
        confirmCancellation: (id: number) => { 
          resetDesktopPage('cancelation');
          confirmCancellation(id); 
        },
        discardCancellation,
        handleBrowsing,
        loadMoreActivities,

        confirmRegistration: (id: number) => { 
          resetDesktopPage('registration'); 
          confirmRegistration(id); 
        },
        discardRegistration,
        handleRegisterChildForActivity
      };
    }
  });
</script>